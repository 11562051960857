export const GA_TRACKING_ID = 'UA-43724455-2'

export const setYaStat = ({ id, name }) => async (dispatch, getState) => {
  if (process.env.NODE_ENV !== 'development') {
    window.ym(54699133, 'reachGoal', name, id)
  }
}

export const sendYaConsultation = () => (dispatch, getState) => {
  setYaStat({name: 'call_sent'})(dispatch, getState)
}

export const bookYaClub = () => (dispatch, getState) => {
  setYaStat({name: 'book_club_pass'})(dispatch, getState)
}

export const clickYaOrderTraining = () => (dispatch, getState) => {
  setYaStat({name: 'click_book_workout'})(dispatch, getState)
}

export const successYaOrderTraining = () => (dispatch, getState) => {
  setYaStat({name: 'successful_booking_workout'})(dispatch, getState)
}

export const showYaBookingCode = () => (dispatch, getState) => {
  setYaStat({name: 'show_booking_code'})(dispatch, getState)
}
