export default {
  getHomeSettings: {
    url: '/site_info',
    isData: true,
    isGlobalApi: true,
    version: '/v1',
  },
  getPrivacy: {
    url: '/privacy_policy',
    version: '/v4',
    isGlobalApi: true
  },
  getAgreement: {
    url: '/user_agreement',
    version: '/v4',
    isGlobalApi: true
  },
  getGlobalMeta: {
    url: '/seo?',
    version: '/v4',
    isGlobalApi: true
  }
}
