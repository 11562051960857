export default {
  getComments: {
    url: '/opinions',
    params: {}
  },
  getClubComments({ id }) {
    return {
      url: '/opinions?club_id=' + id,
      version: '/v4',
      isGlobalApi: true
    }
  }
}
