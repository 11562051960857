export default {
  getBlog: {
    url: '/blog_items'
  },
  subscribeToBlog: {
    api: '/api',
    isGlobalApi: true,
    method: 'post',
    version: '/v1',
    url: `/subscriptions`
  }
}
