import { createParamsApi } from "../../../api"
import { createApiUrl, fetcher } from "@/utils/fetcher"
import { openModal, resetModalModel } from "../../modal/actions"
import { setModalAdditional } from "../../modal/actions"
import moment from "moment"
import { getStatsData } from "@/store/global/actions"
import {
  sendYaConsultation,
  bookYaClub,
  successYaOrderTraining,
  showYaBookingCode
} from "@/store/actions/yaMetrika/actions"
import { updateTrainings } from "@/store/user/trainings/actions"

export const SET_SINGLE_TRAINING_ORDER_DATA = 'SET_SINGLE_TRAINING_ORDER_DATA'
export const SET_CLUB_CARD_ORDER_DATA = 'SET_CLUB_CARD_ORDER_DATA'
export const SET_REMOVE_SINGLE_TRAINING_ORDER_DATA = 'SET_REMOVE_SINGLE_TRAINING_ORDER_DATA'
export const RESET_REMOVE_SINGLE_TRAINING_ORDER_DATA = 'RESET_REMOVE_SINGLE_TRAINING_ORDER_DATA'
export const SET_UKASSA_DATA = 'SET_UKASSA_DATA'
export const RESET_UKASSA_DATA = 'RESET_UKASSA_DATA'

export const getTraining = ({ payId, withoutOpenModal, set }) => async (dispatch, getState) => {
  console.log("Вызов getTraining")
  let api = createGetTrainingApi({ payId })(dispatch, getState)
  let res = await fetcher(api)
  const setCallBack = !set ? setTrainingDefault : set
  await setCallBack({ res, withoutOpenModal })(dispatch, getState)
}

export const setTrainingDefault = ({ res, withoutOpenModal }) => async (dispatch, getState) => {
  const { order, club_user_info } = res
  setModalAdditional({ className: 'autoContainer' })(dispatch, getState)
  if (order.status === 'canceled') setCancelTraining({ withoutOpenModal, order })(dispatch, getState)
  else setTraining({ withoutOpenModal, order, club_user_info })(dispatch, getState)
}

const setTraining = ({ withoutOpenModal, order, club_user_info }) => (dispatch, getState) => {
  const { orderSingleTraining } = getState().modal.modalNames
  const { failed, showVoucher, completed, started } = getSingleStepStatus()(dispatch, getState)
  dispatch({
    type: SET_SINGLE_TRAINING_ORDER_DATA,
    data: {
      step: order.status === 'started' ? started : order.status === 'error' ? failed : order.is_visited ? completed : showVoucher,
      club_user_info,
      item: { ...order }
    }
  })
  console.log(order.status !== 'started', !order.is_visited, !completed, 'setTraining')
  if (order.status !== 'started' && !order.is_visited) {
    let data = checkNeedData()(dispatch, getState)
  }
  if (!withoutOpenModal) openModal(orderSingleTraining)(dispatch, getState)
}

const setCancelTraining = ({ withoutOpenModal, order }) => (dispatch, getState) => {
  const { removeOrderedTraining } = getState().modal.modalNames

  dispatch({
    type: SET_REMOVE_SINGLE_TRAINING_ORDER_DATA,
    data: { fetch: false, step: 1 }
  })
  dispatch({ type: SET_SINGLE_TRAINING_ORDER_DATA, data: { item: { ...order } } })
  if (!withoutOpenModal) openModal(removeOrderedTraining)(dispatch, getState)
}

export const createGetTrainingApi = ({ payId }) => (dispatch, getState) => {
  let api = createParamsApi({
    method: 'getTraining',
    errorMessage: 'getTraining',
    section: 'order',
    apiUrlParams: { id: payId }
  })
  const { signInInfo } = getState().user.auth

  api.params.check_sum = signInInfo.personal_data && signInInfo.personal_data.passport && signInInfo.personal_data.passport.check_sum
  return api
}

export const checkNeedData = ({ showUserData = true } = {}) => (dispatch, getState) => {
  const { club_user_info } = getState().user.order.orderSingle
  console.log({ club_user_info }, 'checkNeedData1')
  try {
    const { passportCookie } = getState().user.auth
    const checkSumCookie = passportCookie.checkSum
    const checkSum = getState().user.auth.signInInfo.personal_data.passport.check_sum

    const { personal_data_required, registered, club_user_data_required } = club_user_info
    console.log({ personal_data_required, registered, club_user_data_required, checkSumCookie, checkSum })
    if (club_user_data_required && !registered || (club_user_data_required && checkSumCookie !== checkSum)) return validPassportData({ showUserData })(dispatch, getState)
    else return true
  } catch (err) {
    console.log(err)
    return 'typeErr'
  }
}

export const openUserDataModal = () => (dispatch, getState) => {
  const { club_user_info } = getState().user.order.orderSingle
  const { personal_data_required, registered, club_user_data_required } = club_user_info
  return club_user_data_required
}

export const validPassportData = ({ showUserData }) => (dispatch, getState) => {
  const { passportCookie } = getState().user.auth
  const data = passportCookie.encData
  const checkSumCookie = passportCookie.checkSum
  const checkSum = getState().user.auth.signInInfo.personal_data.passport.check_sum
  console.log(data, checkSum, checkSumCookie)
  const { item, userData } = getState().user.order.orderSingle
  if (showUserData) dispatch({
    type: SET_SINGLE_TRAINING_ORDER_DATA,
    data: {
      step: getSingleStepStatus()(dispatch, getState).userData,
      item: { ...item },
      userData: { ...userData, withoutPassport: false }
    }
  })
  if (data && checkSum === checkSumCookie && checkSum && checkSumCookie) {
    console.log({ data })
    return data
  }
}

export const changeSaveLocalPassport = (status) => (dispatch, getState) => {
  const { userData } = getState().user.order.orderSingle
  dispatch({
    type: SET_SINGLE_TRAINING_ORDER_DATA,
    data: {
      userData: { ...userData, saveLocalPassport: status }
    }
  })
}

export const changeWithoutPassport = (status) => (dispatch, getState) => {
  const { userData } = getState().user.order.orderSingle
  dispatch({
    type: SET_SINGLE_TRAINING_ORDER_DATA,
    data: {
      userData: { ...userData, withoutPassport: status }
    }
  })
}

export const getSingleStepStatus = () => (dispatch, getState) => getState().user.order.singleStepStatus

export const orderSingleTraining = ({
                                      date,
                                      errorMessage = 'orderSingleTraining'
                                    }) => async (dispatch, getState) => {
  const isValid = date
  dispatch({ type: SET_SINGLE_TRAINING_ORDER_DATA, data: { fetch: true } })
  // if (!isValid) return

  const { phone } = getState().user.auth.signInInfo
  const api = createSingleTrainingParams({ phone, date, errorMessage, isAuth: false })(dispatch, getState)
  let data = await fetcher(api)
  await orderSingleTrainingIsNotAuth({ data })(dispatch, getState)
  dispatch({ type: SET_SINGLE_TRAINING_ORDER_DATA, data: { fetch: false } })
}

export const orderSingleTrainingIsNotAuth = ({ data }) => async (dispatch, getState) => {
  // do something
  successYaOrderTraining()(dispatch, getState)
  let { order, club_user_info } = data
  const payment_integration = order.club && order.club.payment_integration
  const item = getState().user.order.orderSingle.item
  dispatch({
    type: SET_SINGLE_TRAINING_ORDER_DATA,
    data: { item: { ...item, ...order }, club_user_info }
  })

  const { payCard } = getState().user.auth
  console.log({ payment_integration })
  if (!payment_integration) await orderSingleTrainingCash()(dispatch, getState)
  else if (payCard.number) {
    dispatch({
      type: SET_SINGLE_TRAINING_ORDER_DATA,
      data: { step: getSingleStepStatus()(dispatch, getState).withBindCard }
    })
  }
  else {
    console.log({ payCard: payCard.number })
    await orderSingleDefault()(dispatch, getState)
  }
}


export const orderWithBindCard = () => async (dispatch, getState) => {
  dispatch({ type: SET_SINGLE_TRAINING_ORDER_DATA, data: { fetch: true } })
  await initUKassa({ payType: 'autopayment' })(dispatch, getState)
  await getTraining({ payId: getState().user.order.orderSingle.item.id })(dispatch, getState)
  dispatch({ type: SET_SINGLE_TRAINING_ORDER_DATA, data: { fetch: false } })
  successYaOrderTraining()(dispatch, getState)
  showYaBookingCode()(dispatch, getState)
}

export const orderSingleDefault = () => async (dispatch, getState) => {
  console.log("orderSingleDefault")
  dispatch({
    type: SET_SINGLE_TRAINING_ORDER_DATA,
    data: { step: getSingleStepStatus()(dispatch, getState).order }
  })
  await initUKassa()(dispatch, getState)
}

export const orderSingleTrainingCash = () => async (dispatch, getState) => {
  const item = getState().user.order.orderSingle.item
  dispatch({ type: SET_SINGLE_TRAINING_ORDER_DATA, data: { item: { ...item }, fetch: true } })
  await initUKassa({ payType: 'cash' })(dispatch, getState)
  checkNeedData()(dispatch, getState)

  if (!openUserDataModal()(dispatch, getState)) dispatch({
    type: SET_SINGLE_TRAINING_ORDER_DATA,
    data: { step: getSingleStepStatus()(dispatch, getState).showVoucher }
  })
  dispatch({ type: SET_SINGLE_TRAINING_ORDER_DATA, data: { item: { ...item }, fetch: false } })
}

const initUKassa = ({ payType = 'web' } = {}) => async (dispatch, getState) => {
  let api = createInitUKassaApi({ payType })(dispatch, getState)
  let res = await fetcher(api)
  const confirmation_token = res.order && res.order.payment && res.order.payment.confirmation_token
  dispatch({ type: SET_UKASSA_DATA, data: { confirmation_token } })
}

export const createInitUKassaApi = ({ payType }) => (dispatch, getState) => {
  const { id } = getState().user.order.orderSingle.item
  let api = createParamsApi({
    section: 'auth',
    method: 'initialUKassa',
    apiUrlParams: { id }
  })
  api.params.type = payType
  return api
}

export const createSingleTrainingParams = ({ phone, date, errorMessage, isAuth }) => (dispatch, getState) => {
  const id = getState().user.order.orderSingle.item.club ? getState().user.order.orderSingle.item.club.id : getState().club.global.id
  const workoutId = getState().user.order.orderSingle.item.workout ? getState().user.order.orderSingle.item.workout.id : getState().user.order.orderSingle.item.id
  const { signInInfo } = getState().user.auth
  const apiUrlParams = { id }
  const method = 'orderSingleTraining'
  let api = createParamsApi({
    section: 'order',
    method,
    errorMessage,
    apiUrlParams,
  })
  api.params = {
    workout_id: workoutId,
    phone,
    ...getStatsData(),
    check_sum: signInInfo.personal_data && signInInfo.personal_data.passport && signInInfo.personal_data.passport.check_sum,
    date: moment(date).format('DD.MM.YYYY')
  }
  return api
}

export const orderClubCard = ({ phone, name, date, errorMessage = 'orderClubCard' }) => async (dispatch, getState) => {
  // hardCode auth, need getState value
  dispatch({ type: SET_CLUB_CARD_ORDER_DATA, data: { fetch: true } })
  const isAuth = false
  const api = createClubCardParams({ phone, name, date, errorMessage, isAuth })(dispatch, getState)
  let data
  try {
    data = await fetcher(api)
  } catch (err) {

  }
  await orderClubCardIsNotAuth({ data })(dispatch, getState)
  dispatch({ type: SET_CLUB_CARD_ORDER_DATA, data: { fetch: false } })
}

const orderClubCardIsNotAuth = ({ data }) => async (dispatch, getState) => {
  resetModalModel()(dispatch, getState)
  bookYaClub()(dispatch, getState)
  setTimeout(() => {
    openModal(getState().modal.modalNames.orderClubCardSuccess)(dispatch, getState)
  }, 500)
}

export const createClubCardParams = ({ phone, name, errorMessage, isAuth }) => (dispatch, getState) => {
  const { id } = getState().club.global
  const cardId = getState().user.order.orderClubCard.item.id

  const apiUrlParams = { clubId: id, cardId }
  const method = 'orderClubCard'
  let api = createParamsApi({
    section: 'order',
    method,
    errorMessage,
    apiUrlParams,
  })
  api.params = { phone, ...getStatsData() }
  return api
}

export const sendConsultation = ({ phone, name, errorMessage = 'getConsultation' }) => async (dispatch, getState) => {
  // hardCode auth, need getState value
  const date = ''
  const isAuth = false
  const api = createConsultationParams({ phone, name, date, errorMessage, isAuth })(dispatch, getState)
  let res = await fetcher(api)
  await getConsultationIsNotAuth()(dispatch, getState)
}

const getConsultationIsNotAuth = () => async (dispatch, getState) => {
  openModal(getState().modal.modalNames.consultationSend)(dispatch, getState)
  sendYaConsultation()(dispatch, getState)
  // do something
}

export const createConsultationParams = ({ phone, name, errorMessage, isAuth }) => (dispatch, getState) => {
  const method = 'call'
  return createParamsApi({
    section: 'order',
    method,
    errorMessage,
    params: {
      phone,
      ...getStatsData(),
      name
    }
  })
}

export const removeOrderedSingle = () => async (dispatch, getState) => {
  dispatch({ type: SET_REMOVE_SINGLE_TRAINING_ORDER_DATA, data: { fetch: true } })
  const { id } = getState().user.order.orderSingle.item

  let api = createParamsApi({
    method: 'cancelSingleOrder',
    section: 'order',
    errorMessage: 'removeOrderedSingle',
    apiUrlParams: { id }
  })
  let res = await fetcher(api)
  let copyItem = JSON.parse(JSON.stringify(getState().user.order.orderSingle.item))
  copyItem.status = 'canceled'
  dispatch({
    type: SET_REMOVE_SINGLE_TRAINING_ORDER_DATA,
    data: { fetch: false, step: 1 }
  })
  dispatch({ type: SET_SINGLE_TRAINING_ORDER_DATA, data: { item: copyItem } })
  await updateTrainings({ payId: id })(dispatch, getState)
}

export const resetUkassaData = () => ({ type: RESET_UKASSA_DATA })

export const payWithAnotherCard = () => async (dispatch, getState) => {

}

export const refetchVoucher = ({ payId, attempt = 0, totalAttempt = 10 }) => async (dispatch, getState) => {
  try {
    const payStatus = getState().user.order.orderSingle.item?.payment?.status
    console.log('refetchVoucher', attempt, payStatus)
    if (payStatus === 'pending' && attempt < totalAttempt) {
      await getTraining({ payId, withoutOpenModal: true })(dispatch, getState)
      attempt += 1
      setTimeout(() => {
        console.log('tiktak')
        refetchVoucher({ attempt, totalAttempt, payId })(dispatch, getState)
      }, 2000)
    }
    else return 'refetch failed'
  } catch (err) {
    console.log(err)
    return err
  }
}

export const showCancelStep = () => (dispatch, getState) => {
  const { modalName, modalNames } = getState().modal
  console.log(modalName, modalNames.orderSingleTraining)
  if (modalName === modalNames.orderSingleTraining) {
    let { order } = getState().user
    let { singleStepStatus } = order
    let { step, userData } = order.orderSingle
    const statusList = [ singleStepStatus.chooseDate, singleStepStatus.order, singleStepStatus.withBindCard, singleStepStatus.userData ]
    console.log(modalName, modalNames.orderSingleTraining, { statusList, step })

    if (statusList.includes(step)) {
      if (step === singleStepStatus.userData && userData.withoutPassport) return false
      dispatch({ type: SET_SINGLE_TRAINING_ORDER_DATA, data: { step: singleStepStatus.cancelOrder } })
      return true
    }
    else {

      if (step === singleStepStatus.cancelOrder) return true
    }
  }
}
