export default {
  orderSingleTraining({ id, slug }) {
    const urlParam = slug || id
    return {
      url: `/club/${ urlParam }/book`,
      version: '/v5',
      isGlobalApi: true,
      method: 'post'
    }
  },
  orderClubCard({ clubId, cardId }) {
    return {
      url: `/club/${ clubId }/club_cards/${ cardId }/order`,
      version: '/v4',
      isGlobalApi: true,
      method: 'post'
    }
  },
  call: {
    url: '/calls',
    version: '/v4',
    isGlobalApi: true,
    method: 'post'
  },
  getTrainings: {
    url: '/orders',
    version: '/v5',
  },
  getTraining: ({ id }) => ({
    url: '/order/' + id,
    version: '/v5',
  }),
  cancelSingleOrder: ({ id }) => ({
    url: `/order/${id}/cancel`,
    version: '/v5',
    method: 'post'
  }),
}
