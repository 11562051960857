export default {
  getClubGlobal: ({ slug, id }) => ({
    url: '/clubs/' + (slug || id),
    version: '/v5',
    isGlobalApi: true
  }),
  getDifferentClubs: ({ slug, id }) => ({
    url: '/nets/' + (slug || id) + '/clubs'
  }),
  getSearchFilters: {
    url: '/filters',
    version: '/v5',
    isGlobalApi: true
  },
  getClubs: {
    url: '/clubs',
    method: 'post',
    version: '/v3',
    isGlobalApi: true
  },
  getAllSettings: {
    url: '/settings',
    version: '/v2',
    isGlobalApi: true
  },
  getServices: {
    url: '/clubs/services'
  },
  getSubways: {
    url: '/clubs/subways'
  },
  getOptions: {
    url: '/clubs/options'
  },
  getNets: {
    url: '/clubs/nets'
  },
  adviseClub: {
    url: '/club_requests',
    version: '/v2',
    isGlobalApi: true,
    method: 'post'
  },
  getPopularFilters: ({ type }) => ({
    url: `/${ type }/top`,
    version: '/v5',
  })
}
