export default {
  signInByNumber: {
    url: '/send-sms-token',
    version: '/v5',
    method: 'post',
  },
  confirmPhone: {
    url: '/auth',
    version: '/v5',
    method: 'post',
    dontShowErrorModal: true
  },
  initialUKassa: ({ id }) => ({
    url: `/order/${ id }/pay`,
    version: '/v5',
    method: 'post'
  }),
  getInfo: {
    url: '/profile',
    version: '/v5',
    dontShowErrorModal: true
  },
  setInfo: {
    url: '/profile',
    version: '/v5',
    method: 'post'
  },
  logOut: {
    url: '/logout',
    version: '/v1',
    method: 'delete'
  },
  removeCurrentCard: {
    url: '/current_card',
    version: '/v5',
    method: 'delete'
  }
}
