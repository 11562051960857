import global from './global'
import popular from './popular'
import blog from './blog'
import faq from './faq'
import club from './club'
import comment from './comment'
import order from './order'
import auth from './auth'
import training from './training'
import promo from './promo'

const api = {
  popular,
  global,
  blog,
  faq,
  club,
  comment,
  order,
  auth,
  training,
  promo
}

export const createParamsApi = ({ method, section, errorMessage, params = {}, apiUrlParams, frontEndToBackEnd }) => {

  let createdApi = api[ section ][ method ]
  if (typeof createdApi === 'function') createdApi = createdApi(apiUrlParams)
  createdApi = { ...createdApi, errorMessage }
  createdApi.params = {}
  Object.keys(params).forEach(key => {
    if (frontEndToBackEnd) {
      if (frontEndToBackEnd[ key ] && (params[ key ] || +params[ key ] === 0)) createdApi.params[ frontEndToBackEnd[ key ] ] = params[ key ]
    }
    else {
      if (params[ key ] || params[ key ] === 0) createdApi.params[ key ] = params[ key ]
    }
  })
  return createdApi
}
