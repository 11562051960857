import { fetcher } from "@/utils/fetcher"
import { createParamsApi } from "../../api"
import { getCookie, setCookie } from "@/utils/cookie"
import { setModalAdditional, openModal } from "@/store/modal/actions"
import stylesDownloadApp from "@/components/Modal/DownloadMobApp/DownloadMobApp.module.scss"

export const SET_DEFAULT_SETTINGS = 'SET_DEFAULT_SETTINGS'
export const TOGGLE_LEFT_MENU_STATE = 'TOGGLE_LEFT_MENU_STATE'
export const SET_TERMS_OR_PRIVACY = 'SET_TERMS_OR_PRIVACY'
export const SET_GLOBAL_META = 'SET_GLOBAL_META'
export const SET_USER_MOB_OS = 'SET_USER_MOB_OS'

export const getGlobal = ({
                            globalMethod = 'getHomeSettings',
                            setSettings = setSettingsDefault
                          } = {}) => async (dispatch, getState) => {
  let api = createParamsApi({ method: globalMethod, errorMessage: 'getGlobal', section: 'global' })
  let data = await fetcher(api)
  setSettings({ data })(dispatch, getState)
}

export const toggleMenuState = ({ status }) => (dispatch, getState) => {
  dispatch({ type: TOGGLE_LEFT_MENU_STATE, status })
}

const setSettingsDefault = ({ data }) => (dispatch, getState) => {
  dispatch({ type: SET_DEFAULT_SETTINGS, settings: data })
}

export const getTermsOrPrivacy = ({ method, saveKey }) => async (dispatch, getState) => {
  let api = createParamsApi({ method: method, errorMessage: 'getTermsOrPrivacy', section: 'global' })
  let res = await fetcher(api)
  console.log(res, 'getTermsOrPrivacy')
  dispatch({ type: SET_TERMS_OR_PRIVACY, data: { [ saveKey ]: res.html } })
}

export const getMeta = ({ router }) => async (dispatch, getState) => {
  const api = createMetaParams({ router })(dispatch, getState)
  console.log(api, 'getMeta')
  let data = await fetcher(api)
  dispatch({ type: SET_GLOBAL_META, data })
}

export const createMetaParams = ({ router }) => (dispatch, getState) => {
  const { pathname, query } = router
  const defaultApi = createParamsApi({ method: 'getGlobalMeta', section: 'global', errorMessage: 'getMeta' })
  const routeToMethod = {
    '/': () => (dispatch, getState) => ({ page: 'root' }),
    'clubs': getMetaClubsParam,
    'club': getMetaClubParam
  }
  const path = pathname.split('/')[ 1 ] || '/'
  console.log(path, 'createMetaParams')
  if (routeToMethod[ path ]) {
    console.log(routeToMethod[ path ])
    defaultApi.params = routeToMethod[ path ]({ router })(dispatch, getState)
  }
  return defaultApi
}

const getMetaClubsParam = ({ router }) => (dispatch, getState) => {
  const { query } = router
  return { service: query.service, option: query.option, city_slug: query.city, subway: query.subway }
}

const getMetaClubParam = ({ router }) => (dispatch, getState) => {
  return { club_id: router.query.slug }
}

export const getStatsData = () => {
  return { stats_data: typeof window !== 'undefined' ? (getCookie('stats_data') ? JSON.parse(getCookie('stats_data')) : null) : null }
}

export const setMobileOs = ({ userAgent }) => (dispatch, getState) => {
  let os = userAgent.match(/Android/i) ? 'android' : userAgent.match(/iPhone|iPad|iPod/i) ? 'ios' : 'other'
  dispatch({ type: SET_USER_MOB_OS, os })
}

export const showInfoForNewUser = ({ router }) => (dispatch, getState) => {
  let gotInfoAtHome = getCookie('gotInfoAtHome')
  let gotInfoAtClub = getCookie('gotInfoAtClub')
  const { sizes, size } = getState().window
  if (size.windowInnerWidth <= sizes.mob) {
    if (!gotInfoAtHome && router.pathname === '/' || router.pathname.indexOf('/club/') === 0 && !gotInfoAtClub) {
      setTimeout(() => {
        const modalNames = getState().modal.modalNames
        setModalAdditional({ className: stylesDownloadApp.modal })(dispatch, getState)
        openModal(modalNames.downloadMobApp)(dispatch, getState)
        setCookie({ key: router.pathname === '/' ? 'gotInfoAtHome' : 'gotInfoAtClub', value: true })
      }, 5000)
    }
  }
}
