import { fetcher } from "@/utils/fetcher"
import { createParamsApi } from "../../../api"

export const RESET_CLUB_MODEL = 'RESET_CLUB_MODEL'
export const CHANGE_CLUB_FETCHING = 'CHANGE_CLUB_FETCHING'
export const SET_CLUB_GLOBAL = 'SET_CLUB_GLOBAL'
export const SET_CLUB_GALLERY_PHOTOS = 'SET_CLUB_GALLERY_PHOTOS'
export const SET_CLUB_CARD_FILTER = 'SET_CLUB_CARD_FILTER'
export const SET_CLUB_CARD_FILTER_ACTIVE = 'SET_CLUB_CARD_FILTER_ACTIVE'

export const resetClubModel = () => (dispatch) => dispatch({ type: RESET_CLUB_MODEL })

export const needGetClub = ({ slug }) => (dispatch, getState) => {
  const currentSlug = getState().club.global.id
  // if (currentSlug === slug && currentSlug) return false
  // else {
    resetClubModel()(dispatch, getState)
    return true
  // }
}

export const setGalleryPhotos = (photo) => (dispatch, getState) => {
  let allPhoto = getState().club.global.photos.slice()
  let indexPhoto = allPhoto.indexOf(photo)
  dispatch({ type: SET_CLUB_GALLERY_PHOTOS, galleryPhotosPosition: indexPhoto })
}

export const getGlobalClub = ({ slug }) => async (dispatch, getState) => {
  let needFetch = needGetClub({ slug })(dispatch, getState)
  if (needFetch) {
    dispatch({ type: CHANGE_CLUB_FETCHING, status: true })
    const api = createParams({ slug })(dispatch, getState)
    const data = await fetcher(api)
    await setGlobalClub({ data })(dispatch, getState)
    dispatch({ type: CHANGE_CLUB_FETCHING, status: false })
  }
}

export const setGlobalClub = ({ data }) => (dispatch, getState) => {
  const { club } = data
  console.log(club, 'setGlobalClub')
  if (club?.club_cards) setClubCardFilter({ clubCards: JSON.parse(JSON.stringify(club.club_cards)) })(dispatch, getState)
  dispatch({ type: SET_CLUB_GLOBAL, info: club })
}

const setClubCardFilter = ({ clubCards }) => (dispatch, getState) => {
  clubCards = clubCards
    .sort((a, b) => a.card_validity.num - b.card_validity.num)
    .filter((item, index) => !index ? true : item.card_validity.num !== clubCards[ index - 1 ].card_validity.num)
    .map(item => ({ code: item.card_validity.num, label: item.card_validity.num + ' ' + item.card_validity.type_str }))

  clubCards.unshift({ code: 'all', label: 'Все' })
  dispatch({ type: SET_CLUB_CARD_FILTER, clubCards })
  setClubCardActiveFilter(clubCards[ 0 ])(dispatch, getState)
}

export const setClubCardActiveFilter = item => (dispatch, getState) => {
  dispatch({
    type: SET_CLUB_CARD_FILTER_ACTIVE,
    active: item.code
  })
}

export const createParams = ({ slug }) => (dispatch, getState) => {
  return createParamsApi({
    method: 'getClubGlobal',
    errorMessage: 'getGlobalClub',
    section: 'club',
    apiUrlParams: { slug }
  })
}
