import { createParamsApi } from "../../../api"
import { fetcher } from "@/utils/fetcher"
import {
  checkNeedData,
  getSingleStepStatus,
  getTraining,
  SET_SINGLE_TRAINING_ORDER_DATA
} from "@/store/user/order/actions"
import { openErrorModal } from "@/store/modal/actions"
import { getGlobalClub } from "@/store/club/global/actions";

export const SET_TRAININGS = 'SET_TRAININGS'
export const RESET_TRAININGS = 'RESET_TRAININGS'
export const SET_TRAININGS_COUNT = 'SET_TRAININGS_COUNT'
export const SET_TRAININGS_ACTIVE_COUNT = 'SET_TRAININGS_ACTIVE_COUNT'
export const CHANGE_TRAININGS_FETCH = 'CHANGE_TRAININGS_FETCH'
export const CHANGE_TRAININGS_PAGINATION = 'CHANGE_TRAININGS_PAGINATION'
export const RESET_TRAININGS_PAGINATION = 'RESET_TRAININGS_PAGINATION'

export const resetTrainingPagination = () => ({ type: RESET_TRAININGS_PAGINATION })
export const resetTrainingList = () => ({ type: RESET_TRAININGS })

export const getTrainings = ({
                               get,
                               errorMessage = 'getTrainings',
                               set,
                               id
                             }) => async (dispatch, getState) => {
  dispatch({ type: CHANGE_TRAININGS_FETCH, status: true })
  let api = createApi({ get, id, errorMessage })(dispatch, getState)
  console.log({api})
  let res = await fetcher(api)
  console.log({res})

  set ? set({ data: res })(dispatch, getState) : setTrainings({ data: res })(dispatch, getState)
  dispatch({ type: CHANGE_TRAININGS_FETCH, status: false })
  changePagination()(dispatch, getState)
}

export const setActiveTrainingCount = ({ data }) => (dispatch, getState) => {
  const { orders } = data
  const count = orders.reduce((acc, item) => {
    if (!item.is_visited && item.status !== 'canceled' && item.status !== 'started' && item.status !== 'completed') acc += 1
    return acc
  }, 0)
  dispatch({ type: SET_TRAININGS_ACTIVE_COUNT, count })
}

export const createApi = ({ get, id, errorMessage }) => (dispatch, getState) => {
  let api = createParamsApi({
    method: get,
    errorMessage,
    section: 'order',
    apiUrlParams: { id },
  })
  if (id === undefined) {
    const { trainings } = getState().user
    const { signInInfo } = getState().user.auth
    const { pagination } = trainings
    const { limit, start } = pagination
    api.params = {
      limit,
      start_position: start * limit,
      check_sum: signInInfo.personal_data && signInInfo.personal_data.passport && signInInfo.personal_data.passport.check_sum
    }
  }
  return api
}

export const setTrainings = ({ data }) => (dispatch, getState) => {
  let { orders, orders_count } = data
  const trainingsList = getState().user.trainings.list
  let formattedData = trainingsList.length > 0 ? JSON.parse(JSON.stringify(trainingsList)) : []
  orders.sort((a, b) => a.date - b.date < 1 ? 1 : a.date - b.date > 1 ? -1 : 0)
    .forEach(item => {
      if (!formattedData.length ||
        (formattedData.length && formattedData[ formattedData.length - 1 ].date !== item.date)
      )
        formattedData.push({
          date: item.date,
          list: { [ item.id ]: item }
        })
      else {
        formattedData[ formattedData.length - 1 ].list[ item.id ] = item
      }
    })

  dispatch({ type: SET_TRAININGS, list: formattedData })
  dispatch({ type: SET_TRAININGS_COUNT, count: orders_count })
}

const changePagination = () => (dispatch, getState) => {
  const { trainings } = getState().user
  const { pagination } = trainings
  const { start } = pagination
  dispatch({ type: CHANGE_TRAININGS_PAGINATION, pagination: { start: start + 1 } })
}

export const startTraining = () => async (dispatch, getState) => {
  const { started } = getSingleStepStatus()(dispatch, getState)
  dispatch({ type: SET_SINGLE_TRAINING_ORDER_DATA, data: { fetch: true } })
  try {
    const { item } = getState().user.order.orderSingle
    let { order } = await fetcher(createStartApi()(dispatch, getState))
    dispatch({
      type: SET_SINGLE_TRAINING_ORDER_DATA,
      data: { fetch: false, step: started, item: { ...item, ...order } }
    })
    // callback
    await updateTrainings({ payId: getState().user.order.orderSingle.item.id })(dispatch, getState)

  } catch (err) {
    console.log(err.response)
    startTrainingError({ data: err.response.data })(dispatch, getState)
  }
}

const startTrainingError = ({ data }) => (dispatch, getState) => {
  try {
    const { code } = data
    console.log(code, data)
    if (code === 1001) {
      console.log(code, data)
      const { doesntActive } = getSingleStepStatus()(dispatch, getState)
      dispatch({
        type: SET_SINGLE_TRAINING_ORDER_DATA,
        data: { fetch: false, step: doesntActive }
      })
    }
    else openErrorModal({ data })(dispatch, getState)
  } catch (err) {

  }
}

export const createStartApi = () => (dispatch, getState) => {
  const { item } = getState().user.order.orderSingle
  let api = createParamsApi({
    method: 'start',
    apiUrlParams: { id: item.id },
    section: 'training',
    errorMessage: 'startTraining'
  })
  api.errorObj = true
  const passport = checkNeedData({ showUserData: false })(dispatch, getState)
  console.log({ passport })
  if (passport && typeof passport === "string") api.params.passport = passport
  return api
}

export const updateTrainings = ({ payId }) => async (dispatch, getState) => {
  await getTraining({ payId, set: setUpdatedTraining })(dispatch, getState)
  await getTrainings({
    get: 'getTrainings',
    set: setActiveTrainingCount,
    id: null
  })(dispatch, getState)
}

export const setUpdatedTraining = ({ res }) => (dispatch, getState) => {
  console.log({ res })
  const { order, club_user_info } = res
  const currentList = getState().user.trainings.list.slice()
  currentList.map(item => {
    if (item.list[ order.id ]) {
      item.list[ order.id ] = { ...order, club_user_info }
    }
    return item
  })
  dispatch({ type: SET_TRAININGS, list: currentList })
}
