export default {
  getClubs: {
    url: '/popular_clubs',
    version: '/v1',
  },
  getDestinations: {
    url: '/popular_destinations',
    version: '/v1',
    isGlobalApi: true
  },
  getComments: {
    url: '/popular_reviews',
    version: '/v1',
  }
}
