import { showCancelStep } from "@/store/user/order/actions"

export const CHANGE_MODAL_OPEN_STATE = 'CHANGE_MODAL_OPEN_STATE'
export const CHANGE_MODAL_NAME = 'CHANGE_MODAL_NAME'
export const RESET_MODAL_MODEL = 'RESET_MODAL_MODEL'
export const RESET_MODAL_ADDITIONAL = 'RESET_MODAL_ADDITIONAL'
export const SET_MODAL_ADDITIONAL = 'SET_MODAL_ADDITIONAL'
export const SET_MODAL_ERROR = 'SET_MODAL_ERROR'

export const setModalAdditional = (additional) => (dispatch) => {
  dispatch({ type: SET_MODAL_ADDITIONAL, additional })
}

export const changeModalOpenState = (status) => (dispatch) => {
  dispatch({ type: CHANGE_MODAL_OPEN_STATE, status })
}

export const changeModalName = (name) => (dispatch) => {
  dispatch({ type: CHANGE_MODAL_NAME, name })
}

export const openModal = (modalName) => (dispatch) => {
  changeModalName(modalName)(dispatch)
  changeModalOpenState(true)(dispatch)
}

export const openErrorModal = ({ data, err }) => (dispatch, getState) => {
  try {
    let message = null
    let code = null
    data = data ? data : (err && err.response && err.response.data) ? err.response.data : undefined
    if (data) {
      const { error, error_code } = data
      message = error || null
      code = error_code || null
    }
    const { modalNames } = getState().modal
    dispatch({ type: SET_MODAL_ERROR, error: { message, code } })
    changeModalName(modalNames.doesWrong)(dispatch, getState)
  } catch (err) {
    /* istanbul ignore next */
    console.log(err)
  }
}

export const resetModalModel = () => (dispatch, getState) => {
  if (showCancelStep()(dispatch, getState)) return
  dispatch({ type: RESET_MODAL_MODEL })
  setTimeout(() => {
    dispatch({ type: RESET_MODAL_ADDITIONAL })
    dispatch({ type: CHANGE_MODAL_NAME, name: '' })
  }, 300)
}
